// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/activestorage").start();
require("channels");
// require("./main");
console.log("applicationsJS");
setTimeout(() => {
    const buttonEl = document.querySelector(".xbuttonMobile");
    const menuEl = document.querySelector("#mobileMenu");
    buttonEl.addEventListener("click", () => {
        console.log("close");
        menuEl.classList.toggle("showMenu");
    });
}, 500);
